import React from "react";
import Routes from "./Routes";

// Context
import { ProvideAuth } from "./context/useAuth";
import { BrowserRouter } from "react-router-dom";
import { ProvideSocket } from "./context/useSocket";

function App() {
  return (
    <div className="main-container">
      <ProvideAuth>
        <ProvideSocket>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ProvideSocket>
      </ProvideAuth>
    </div>
  );
}

export default App;
