import { useState, useEffect } from "react";
import api from "../services/api";
import moment from "moment";
import HeaderAdmin from "../components/HeaderAdmin";
import { useNavigate } from "react-router-dom";

export default function CalendarAI() {
  const [contents, setContents] = useState([]);
  const [params, setParams] = useState({});
  const [generating, setGenerating] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [contentTypes, setContentTypes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContents();
  }, []);

  const fetchContents = async () => {
    let user = await api.getUser();
    setParams({
      description: user.data.promptCalendar,
      activities: "Visiting Bali",
      from: moment().startOf("day").add("1", "days").format("YYYY-MM-DD"),
      to: moment().startOf("day").add("8", "days").format("YYYY-MM-DD"),
      reels: 3,
      posts: 3,
      stories: 6,
    });

    let contentTypes = await api.getContentTypes();
    setContentTypes(contentTypes.data);
  };

  const createCalendar = async () => {
    setGenerating(true);
    try {
      let contents = await api.generateCalendar(params);

      if (contents.ok) {
        contents.data = contents.data.map((c) => {
          let type = contentTypes.find((type) => c.type === type.instagramType);
          c._contentType = type ? type._id : contentTypes[0]._id;
          return c;
        });

        contents.data = contents.data.sort(sortFn);
        setContents(contents.data);
      } else {
        alert(contents.data ? contents.data.message : contents.data);
      }
      setGenerating(false);
    } catch (e) {
      alert("Error");
      console.error(e);
      setGenerating(false);
    }
  };

  const save = async () => {
    let calendar = await api.saveCalendar(contents);
    if (calendar.ok) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else {
      alert(calendar.data.message);
    }
  };

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setParams({ ...params, ...newObj });
  };

  const setValueContent = (i, key, value) => {
    let newObj = {};
    newObj[key] = value;
    contents[i] = { ...contents[i], ...newObj };
    setContents([...contents]);
  };

  const deleteContent = (i) => {
    contents.splice(i, 1);
    setContents([...contents]);
  };

  const sortFn = (a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2 className="mb-60">Describe your calendar</h2>

        <div>
          <div className="label-row">
            <label>Description</label>
            <textarea value={params.description} onChange={(e) => setValue("description", e.target.value)}></textarea>
          </div>
          <div className="label-row">
            <label>Activities</label>
            <textarea
              value={params.activities}
              placeholder="Visiting Bali"
              onChange={(e) => setValue("activities", e.target.value)}
            ></textarea>
          </div>
          <div className="label-row">
            <label>From</label>
            <input type="date" value={params.from} onChange={(e) => setValue("from", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>To</label>
            <input type="date" value={params.to} onChange={(e) => setValue("to", e.target.value)}></input>
          </div>
          <h4>Contents</h4>
          <div className="label-row">
            <label>Stories per day</label>
            <input type="number" value={params.stories} onChange={(e) => setValue("stories", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>Posts</label>
            <input type="number" value={params.posts} onChange={(e) => setValue("posts", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>Reels</label>
            <input type="number" value={params.reels} onChange={(e) => setValue("reels", e.target.value)}></input>
          </div>
        </div>

        <div className="btn-bar">
          <button
            disabled={generating}
            onClick={() => {
              createCalendar();
            }}
          >
            {generating ? "Generating..." : "Generate"}
          </button>
        </div>

        <h2>Contents generated</h2>

        <div className="btn-bar">
          <button
            disabled={contents.length === 0}
            onClick={() => {
              setEditMode(!!!editMode);
            }}
          >
            {editMode ? "View" : "Edit"}
          </button>
        </div>

        <div>
          {contents.map((content, i) => (
            <>
              {i === 0 && <h5>{moment(content.date).format("dddd Do MMMM")}</h5>}
              {i > 1 && moment(content.date).day() !== moment(contents[i - 1].date).day() && (
                <>
                  <div className="separator"></div>
                  <h5>{moment(content.date).format("dddd Do MMMM")}</h5>
                </>
              )}

              <div key={i} className="row-calendar">
                {editMode ? (
                  <>
                    <select
                      className="type"
                      value={content._contentType}
                      onChange={(e) => setValueContent(i, "_contentType", e.target.value)}
                    >
                      {contentTypes.map((c) => (
                        <option key={c._id} value={c._id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                    <input
                      className="date"
                      type="datetime-local"
                      value={moment(content.date).format("YYYY-MM-DD HH:mm:ss")}
                      onChange={(e) => setValueContent(i, "date", e.target.value)}
                    ></input>
                    <input
                      className="location"
                      value={content.location}
                      onChange={(e) => setValueContent(i, "location", e.target.value)}
                    ></input>
                    <input
                      className="city"
                      value={content.city}
                      onChange={(e) => setValueContent(i, "city", e.target.value)}
                    ></input>
                    <textarea
                      className="description"
                      value={content.description}
                      onChange={(e) => setValueContent(i, "description", e.target.value)}
                    ></textarea>
                    <button className="delete" onClick={() => deleteContent(i)}>
                      X
                    </button>
                  </>
                ) : (
                  <div className="read">
                    <div className="type">
                      <div className={"badge type-" + content.type}>{content.type}</div>
                    </div>
                    <div className="date">{moment(content.date).format("HH:mm")}</div>
                    <div className="location" title={content.location}>
                      {content.location}
                    </div>
                    <div className="city" title={content.city}>
                      {content.city}
                    </div>
                    <div className="description">{content.description}</div>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>

        <div className="btn-bar">
          <button
            disabled={contents.length === 0}
            onClick={() => {
              save();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
