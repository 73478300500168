import { useEffect, useState } from "react";
import api from "../services/api";
import { useNavigate } from "react-router-dom";

export default function WizardModal({ close }) {
  const [content, setContent] = useState({});
  const [contentTypes, setContentTypes] = useState([]);
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchContents();
  }, []);

  const fetchContents = async () => {
    let contentTypes = await api.getContentTypes();
    setContentTypes(contentTypes.data);
  };

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setContent({ ...content, ...newObj });
  };

  const save = async () => {
    setGenerating(true);
    let contentData = await api.createContentWizard(content);
    setGenerating(false);
    if (contentData.ok) {
      navigate("/contents/" + contentData.data._id);
    } else {
      alert(contentData.data.message);
    }
  };

  const saveAndContinue = async () => {
    api.createContentWizard(content);
    content.description = "";
    setContent({ ...content });
  };

  return (
    <>
      <div className="modal-backdrop">
        <div className="modal full-mobile">
          <button
            className="close-btn"
            onClick={() => {
              close();
            }}
          >
            X
          </button>

          <div className="modal-center">
            <h2>Describe your content</h2>

            <div>
              <div className="label-row">
                <label>Description</label>
                <input value={content.description} onChange={(e) => setValue("description", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Content Type</label>
                <select value={content._contentType} onChange={(e) => setValue("_contentType", e.target.value)}>
                  <option></option>
                  {contentTypes.map((c) => (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="label-row">
                <label>Date</label>
                <input type="datetime-local" value={content.date} onChange={(e) => setValue("date", e.target.value)}></input>
              </div>
            </div>
            <button
              disabled={generating}
              onClick={() => {
                save();
              }}
            >
              {generating ? "Generating..." : "Create"}
            </button>
            <button
              style={{ marginTop: 20 }}
              className="dark"
              onClick={() => {
                saveAndContinue();
              }}
            >
              Create and add new
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
