import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import HeaderAdmin from "../components/HeaderAdmin";

// Api
import api from "../services/api";

// Context

export default function ContentTypeList() {
  const [contents, setContents] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    let contents = await api.getContentTypes();
    if (contents.status === 200) setContents(contents.data);
  };

  const addContent = () => {
    navigate("/contentTypes/create");
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Content Types</h2>

        <div className="btn-bar">
          <div className="btn-bar-left">
            <button onClick={addContent} className="btn">
              Add Content Type
            </button>
          </div>
        </div>

        {contents && (
          <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <td>Name</td>
                  </tr>
                </thead>
                <tbody>
                  {contents.map((content, i) => (
                    <tr key={i}>
                      <td>
                        <Link to={"/contentTypes/" + content._id}>{content.name}</Link>
                      </td>
                    </tr>
                  ))}

                  {contents.length === 0 && (
                    <tr>
                      <td colSpan="2">
                        <div style={{ padding: "5px 15px" }}>No Content Types Found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </>
  );
}
