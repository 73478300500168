import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// Api
import api from "../services/api";

// Components
import HeaderAdmin from "../components/HeaderAdmin";

// Image
import backArrow from "../images/back_arrow.svg";
import igNoImg from "../images/igNoImg2.jpg";
import Modal from "../components/Modal";

export default function ContentTypeEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contentType, setContentType] = useState({});
  const [uploads, setUploads] = useState([]);
  const [showSelectImage, setShowSelectImage] = useState();
  const fileInputRef = useRef();

  useEffect(() => {
    fetchContentTypes();
    // eslint-disable-next-line
  }, [id]);

  const fetchContentTypes = async () => {
    api.getUploadMedia().then((data) => {
      console.log(data);
      setUploads(data.data);
    });
    if (id !== "create") {
      let contentTypes = await api.getContentType(id);
      if (contentTypes.status === 200) {
        setContentType(contentTypes.data);
      }
    } else {
      setContentType({
        cn_img1: "image/base/face 3.png",
        cn_type1: "FaceSwap",
        cn_weight1: 0.9,
        cn_stop1: 1,
        cn_img2: "image/base/face 5.png",
        cn_type2: "FaceSwap",
        cn_weight2: 0.9,
        cn_stop2: 1,
        cn_img3: "image/excited/faceUp.png",
        cn_type3: "FaceSwap",
        cn_weight3: 0.9,
        cn_stop3: 1,
        cn_img4: "image/excited/face excited 5.png",
        cn_type4: "FaceSwap",
        cn_weight4: 0.9,
        cn_stop4: 1,
        negative_prompt:
          "unrealistic, saturated, high contrast, big nose, painting, drawing, sketch, cartoon, anime, manga, render, CG, 3d, watermark, signature, label",
        style_selections: "Fooocus V2,Fooocus Enhance,Fooocus Semi Realistic,Fooocus Photograph",
        performance_selection: "Quality",
        aspect_ratios_selection: "1344*768",
        image_number: 1,
        refiner_switch: 0.5,
        instagramType: "Post",

        captionBg: "linen",
        captionColor: "black",
        captionFont: "Futura",
        captionLeft: "20",
        captionMaxHeight: "500",
        captionMaxWidth: "500",
        captionSize: "30",
        captionTop: "20",
        captionRadius: 20,
      });
    }
  };

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setContentType({ ...contentType, ...newObj });
  };

  const save = async () => {
    let contentTypeObj = {
      ...contentType,
    };
    let data = await api.saveContentType(contentTypeObj);
    if (data.status === 200) {
      navigate("/contentTypes");
    }
  };

  const remove = async () => {
    if (await window.confirm("Are you sure?")) {
      let data = await api.removeContentType(contentType._id);
      if (data.status === 200) {
        navigate("/contentTypes");
      }
    }
  };

  const upload = async (file) => {
    const result = await api.uploadMedia(file.target.files[0]);
    file.target.value = "";
    if (result.status === 200) {
      alert("File Uploaded");
      setUploads(result.data);
    } else {
      alert("Error uploading media");
    }
  };

  const selectImage = (filename) => {
    setValue(showSelectImage, filename);
    setShowSelectImage(false);
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>
          <Link to="/contentTypes" className="back-link">
            <img style={{ height: 30 }} src={backArrow} alt="back" />
          </Link>
          Content Type Edit
        </h2>

        <div className="btn-bar">
          <div className="btn-bar-left">
            <button onClick={save}>Save</button>

            <input style={{ display: "none" }} ref={fileInputRef} onChange={upload} id="uploadButton" type="file"></input>
            <button onClick={() => fileInputRef.current.click()}>Upload Media</button>
          </div>
          <div className="btn-bar-right">
            {contentType._id && (
              <button onClick={remove} className="dark">
                Delete
              </button>
            )}
          </div>
        </div>

        <div>
          <div className="label-row">
            <label>Name</label>
            <input value={contentType.name} onChange={(e) => setValue("name", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>Instagram Content</label>
            <select value={contentType.instagramType} onChange={(e) => setValue("instagramType", e.target.value)}>
              <option value="Reel">Reel</option>
              <option value="Post">Post</option>
              <option value="Story">Story</option>
            </select>
          </div>

          <h4>Image generation</h4>
          <div className="label-row">
            <label>cn_img1</label>
            <div className="img-preview-container">
              <img
                className="img-preview"
                src={contentType.cn_img1 ? process.env.REACT_APP_IMG_URL_UPLOAD + contentType.cn_img1 : igNoImg}
                alt="cn_img1"
              ></img>
              <div>
                <button className="link" onClick={() => setShowSelectImage("cn_img1")}>
                  Select Image
                </button>
                <button className="link" onClick={() => setValue("cn_img1", "")}>
                  Remove Image
                </button>
              </div>
            </div>
          </div>
          <div className="label-row">
            <label>cn_type1</label>
            <select value={contentType.cn_type1} onChange={(e) => setValue("cn_type1", e.target.value)}>
              <option value="FaceSwap">FaceSwap</option>
              <option value="ImagePrompt">ImagePrompt</option>
              <option value="PyraCanny">PyraCanny</option>
              <option value="CPDS">CPDS</option>
            </select>
          </div>
          <div className="label-row">
            <label>cn_weight1</label>
            <input value={contentType.cn_weight1} type="number" onChange={(e) => setValue("cn_weight1", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>cn_stop1</label>
            <input value={contentType.cn_stop1} type="number" onChange={(e) => setValue("cn_stop1", e.target.value)}></input>
          </div>

          <div className="label-row">
            <label>cn_img2</label>
            <div className="img-preview-container">
              <img
                className="img-preview"
                src={contentType.cn_img2 ? process.env.REACT_APP_IMG_URL_UPLOAD + contentType.cn_img2 : igNoImg}
                alt="cn_img2"
              ></img>
              <div>
                <button className="link" onClick={() => setShowSelectImage("cn_img2")}>
                  Select Image
                </button>
                <button className="link" onClick={() => setValue("cn_img2", "")}>
                  Remove Image
                </button>
              </div>
            </div>
          </div>
          <div className="label-row">
            <label>cn_type2</label>
            <select value={contentType.cn_type2} onChange={(e) => setValue("cn_type2", e.target.value)}>
              <option value="FaceSwap">FaceSwap</option>
              <option value="ImagePrompt">ImagePrompt</option>
              <option value="PyraCanny">PyraCanny</option>
              <option value="CPDS">CPDS</option>
            </select>
          </div>
          <div className="label-row">
            <label>cn_weight2</label>
            <input value={contentType.cn_weight2} type="number" onChange={(e) => setValue("cn_weight2", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>cn_stop2</label>
            <input value={contentType.cn_stop2} type="number" onChange={(e) => setValue("cn_stop2", e.target.value)}></input>
          </div>

          <div className="label-row">
            <label>cn_img3</label>
            <div className="img-preview-container">
              <img
                className="img-preview"
                src={contentType.cn_img3 ? process.env.REACT_APP_IMG_URL_UPLOAD + contentType.cn_img3 : igNoImg}
                alt="cn_img3"
              ></img>
              <div>
                <button className="link" onClick={() => setShowSelectImage("cn_img3")}>
                  Select Image
                </button>
                <button className="link" onClick={() => setValue("cn_img3", "")}>
                  Remove Image
                </button>
              </div>
            </div>
          </div>
          <div className="label-row">
            <label>cn_type3</label>
            <select value={contentType.cn_type3} onChange={(e) => setValue("cn_type3", e.target.value)}>
              <option value="FaceSwap">FaceSwap</option>
              <option value="ImagePrompt">ImagePrompt</option>
              <option value="PyraCanny">PyraCanny</option>
              <option value="CPDS">CPDS</option>
            </select>
          </div>
          <div className="label-row">
            <label>cn_weight3</label>
            <input value={contentType.cn_weight3} type="number" onChange={(e) => setValue("cn_weight3", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>cn_stop3</label>
            <input value={contentType.cn_stop3} type="number" onChange={(e) => setValue("cn_stop3", e.target.value)}></input>
          </div>

          <div className="label-row">
            <label>cn_img4</label>
            <div className="img-preview-container">
              <img
                className="img-preview"
                src={contentType.cn_img4 ? process.env.REACT_APP_IMG_URL_UPLOAD + contentType.cn_img4 : igNoImg}
                alt="cn_img4"
              ></img>
              <div>
                <button className="link" onClick={() => setShowSelectImage("cn_img4")}>
                  Select Image
                </button>
                <button className="link" onClick={() => setValue("cn_img4", "")}>
                  Remove Image
                </button>
              </div>
            </div>
          </div>
          <div className="label-row">
            <label>cn_type4</label>
            <select value={contentType.cn_type4} onChange={(e) => setValue("cn_type4", e.target.value)}>
              <option value="FaceSwap">FaceSwap</option>
              <option value="ImagePrompt">ImagePrompt</option>
              <option value="PyraCanny">PyraCanny</option>
              <option value="CPDS">CPDS</option>
            </select>
          </div>
          <div className="label-row">
            <label>cn_weight4</label>
            <input value={contentType.cn_weight4} type="number" onChange={(e) => setValue("cn_weight4", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>cn_stop4</label>
            <input value={contentType.cn_stop4} type="number" onChange={(e) => setValue("cn_stop4", e.target.value)}></input>
          </div>

          <div className="label-row">
            <label>negative_prompt</label>
            <input value={contentType.negative_prompt} onChange={(e) => setValue("negative_prompt", e.target.value)}></input>
          </div>
          <div className="label-row">
            <label>style_selections</label>
            <input
              value={contentType.style_selections}
              defalut="Fooocus V2,Fooocus Enhance,Fooocus Semi Realistic,Fooocus Photograph"
              onChange={(e) => setValue("style_selections", e.target.value)}
            ></input>
          </div>
          <div className="label-row">
            <label>performance_selection</label>
            <select value={contentType.performance_selection} onChange={(e) => setValue("performance_selection", e.target.value)}>
              <option value="Quality">Quality</option>
              <option value="Speed">Speed</option>
              <option value="Extreme Speed">Extreme Speed</option>
            </select>
          </div>
          <div className="label-row">
            <label>aspect_ratios_selection</label>
            <select
              value={contentType.aspect_ratios_selection}
              onChange={(e) => setValue("aspect_ratios_selection", e.target.value)}
            >
              <option value="1344*768">(Wide) 1344*768</option>
              <option value="896*1088">(Post) 896*1088</option>
              <option value="768*1344">(Story) 768*1344</option>
              <option value="1536*640">(Banner) 1536*640</option>
              <option value="1024*1024">(Square) 1024*1024</option>
            </select>
          </div>
          <div className="label-row">
            <label>image_number</label>
            <input
              type="number"
              value={contentType.image_number}
              onChange={(e) => setValue("image_number", e.target.value)}
            ></input>
          </div>
          <div className="label-row">
            <label>refiner_switch</label>
            <input
              type="number"
              value={contentType.refiner_switch}
              onChange={(e) => setValue("refiner_switch", e.target.value)}
            ></input>
          </div>

          <h4>Image Caption</h4>

          <div className="label-row">
            <label>Enable Caption</label>
            <input
              type="checkbox"
              checked={contentType?.enableCaption}
              onChange={(e) => setValue("enableCaption", e.target.checked)}
            />
          </div>
          <div className="label-row">
            <label>Caption Font</label>
            <input value={contentType?.captionFont} onChange={(e) => setValue("captionFont", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Caption Size</label>
            <input type="number" value={contentType?.captionSize} onChange={(e) => setValue("captionSize", e.target.value)} />
          </div>

          <div className="label-row">
            <label>Caption Radius</label>
            <input type="number" value={contentType?.captionRadius} onChange={(e) => setValue("captionRadius", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Caption Padding</label>
            <input value={contentType?.captionPadding} onChange={(e) => setValue("captionPadding", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Caption Opacity</label>
            <input
              type="number"
              step="0.1"
              min="0"
              max="1"
              value={contentType?.captionOpacity}
              onChange={(e) => setValue("captionOpacity", e.target.value)}
            />
          </div>
          <div className="label-row">
            <label>Caption Weight</label>
            <input value={contentType?.captionWeight} onChange={(e) => setValue("captionWeight", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Caption Line Height</label>
            <input
              type="number"
              step="0.1"
              value={contentType?.captionLineHeight}
              onChange={(e) => setValue("captionLineHeight", e.target.value)}
            />
          </div>
          <div className="label-row">
            <label>Caption Color</label>
            <input
              className="color-input"
              value={contentType?.captionColor}
              onChange={(e) => setValue("captionColor", e.target.value)}
            />
            <div className="color-wrapper">
              <input type="color" value={contentType?.captionColor} onChange={(e) => setValue("captionColor", e.target.value)} />
            </div>
          </div>
          <div className="label-row">
            <label>Caption Bg</label>
            <input
              className="color-input"
              value={contentType?.captionBg}
              onChange={(e) => setValue("captionBg", e.target.value)}
            />
            <div className="color-wrapper">
              <input type="color" value={contentType?.captionBg} onChange={(e) => setValue("captionBg", e.target.value)} />
            </div>
          </div>
          <div className="label-row">
            <label>Caption Left</label>
            <input type="number" value={contentType?.captionLeft} onChange={(e) => setValue("captionLeft", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Caption Top</label>
            <input type="number" value={contentType?.captionTop} onChange={(e) => setValue("captionTop", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Caption Max Width</label>
            <input
              type="number"
              value={contentType?.captionMaxWidth}
              onChange={(e) => setValue("captionMaxWidth", e.target.value)}
            />
          </div>
          <div className="label-row">
            <label>Caption Max Height</label>
            <input
              type="number"
              value={contentType?.captionMaxHeight}
              onChange={(e) => setValue("captionMaxHeight", e.target.value)}
            />
          </div>
        </div>

        {showSelectImage && (
          <Modal close={() => setShowSelectImage(false)}>
            <h2>Select Image</h2>

            <div className="modal-img-select">
              {uploads.map((u) => (
                <div key={u.filename} className="img-select-container" onClick={() => selectImage(u.filename)}>
                  <img style={{ marginTop: 0 }} src={process.env.REACT_APP_IMG_URL_UPLOAD + u.filename} alt={u.filename}></img>
                </div>
              ))}
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
