import React, { useState, useContext, createContext } from "react";

// APIs
import api from "../services/api";

export const authContext = createContext();

function useAuthProvider() {
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const login = async (user, pwd) => {
    let result = await api.login(user, pwd);

    if (result.status === 200 && result.data.token) {
      localStorage.setItem("user_token", result.data.token);
      checkAuth();
      // window.location = "/admin";
      return true;
    } else {
      localStorage.removeItem("user_token");
      return false;
    }
  };

  const logout = async () => {
    localStorage.removeItem("user_token");
    setToken(null);
    setUser(null);
    window.location = "/";
  };

  const checkAuth = async () => {
    let searchParams = new URLSearchParams(document.location.search);
    let token = searchParams.get("token") || localStorage.getItem("user_token");
    if (token) {
      localStorage.setItem("user_token", token);
      setToken(token);
      api.setToken(token);

      const response = await api.getUser();

      if (response.status === 200 && response.data) {
        setUser(response.data);
      } else {
        // if (window.location.pathname !== "/login") window.location = "/login";
      }
    } else {
      // if (window.location.pathname !== "/login") window.location = "/login";
    }
  };

  const successLoginGoogle = async (credentials) => {
    let result = await api.registerGoogle(credentials.credential);
    if (result.status === 200 && result.data.token) {
      localStorage.setItem("user_token", result.data.token);
      checkAuth();
      window.location = "/login";
      return true;
    } else {
      localStorage.removeItem("user_token");
      return false;
    }
  };

  return {
    token,
    user,
    setToken,
    checkAuth,
    logout,
    login,
    successLoginGoogle,
  };
}

export function ProvideAuth({ children }) {
  const values = useAuthProvider();
  return <authContext.Provider value={values}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
