export default function Modal({ close, children, size }) {
  return (
    <>
      <div className="modal-backdrop">
        <div className={"modal full-mobile " + size}>
          <button
            className="close-btn"
            onClick={() => {
              close();
            }}
          >
            X
          </button>

          <div className="modal-center">{children}</div>
        </div>
      </div>
    </>
  );
}
