import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";

// Components
import HeaderAdmin from "../components/HeaderAdmin";

// Api
import api from "../services/api";
import WizardModal from "../components/WizardModal";
import Table from "../components/Table";
import moment from "moment";

// Context

export default function ContentList() {
  const [contents, setContents] = useState();
  const [showWizardModal, setShowWizardModal] = useState();
  const [showFilter, setShowFilter] = useState();
  const [showList, setShowList] = useState();
  const [filter, setFilter] = useState({});
  const navigate = useNavigate();
  const [contentTypes, setContentTypes] = useState([]);

  const closeWizardModal = () => {
    fetchContent();
    setShowWizardModal(false);
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const config = {
    columns: [
      {
        header: "Title",
        key: "title",
        template: (item) => <Link to={"/contents/" + item._id}>{item.title || "NO_NAME"}</Link>,
      },
      {
        width: 160,
        header: "Date",
        key: "date",
        template: (item) => (item.date ? <Moment format="MM-DD-YYYY HH:mm">{item.date}</Moment> : ""),
      },
      {
        width: 100,
        header: "City",
        key: "city",
        template: (item) => item.city,
      },
      {
        width: 80,
        header: "Type",
        key: "_contentType.name",
        template: (item) => (
          <>{item._contentType && <div className={"badge type-" + item._contentType?.name}>{item._contentType?.name}</div>}</>
        ),
      },
      {
        width: 90,
        header: "Status",
        key: "status",
        template: (item) => <div className={"badge status-" + (item.status || "Draft")}>{item.status || "Draft"}</div>,
      },
    ],
  };

  const setFilterValue = (key) => {
    return (e) => {
      let set = {};
      set[key] = e.target.value;
      console.log(set);
      setFilter({ ...filter, ...set });
    };
  };

  const fetchContent = async () => {
    api.getContentTypes().then((contentTypes) => {
      setContentTypes(contentTypes.data);
    });

    let contents = await api.getContents();
    if (contents.status === 200) setContents(contents.data);
  };

  const addContent = () => {
    navigate("/contents/create");
  };

  const goToCalendarAI = () => {
    navigate("/calendarAI");
  };

  const sortFn = (a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Contents</h2>

        <div className="btn-bar">
          <div className="btn-bar-left">
            <button onClick={() => setShowWizardModal(true)} className="btn">
              Magic AI
            </button>
            <button onClick={addContent} className="btn">
              Add Content
            </button>
            <button onClick={() => goToCalendarAI()} className="btn">
              AI Calendar
            </button>
          </div>
          <div className="btn-bar-right">
            <button onClick={() => setShowFilter(!!!showFilter)} className="link">
              {showFilter ? "Hide Filters" : "Show Filters"}
            </button>
            <button onClick={() => setShowList(!!!showList)} className="link">
              {showList ? "Show Table View" : "Show List View"}
            </button>
          </div>
        </div>

        {showWizardModal && <WizardModal close={closeWizardModal}></WizardModal>}

        {showFilter && (
          <div className="filter-container">
            <div className="filter-row">
              <div className="filter-label">Title</div>
              <div className="filter-data">
                <input onChange={setFilterValue("title")} />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-label">City</div>
              <div className="filter-data">
                <input onChange={setFilterValue("city")} />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-label">Status</div>
              <div className="filter-data">
                <input onChange={setFilterValue("status")} />
              </div>
            </div>

            <div className="filter-row">
              <div className="filter-label">Type</div>
              <div className="filter-data">
                <select onChange={setFilterValue("_contentType._id")}>
                  <option></option>
                  {contentTypes.map((c) => (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}

        {contents && (
          <>
            {showList ? (
              <div className="mt-40">
                {contents.sort(sortFn).map((content, i) => (
                  <Fragment key={"frag_" + i}>
                    {i === 0 && <h5>{moment(content.date).format("dddd Do MMMM")}</h5>}
                    {i > 1 && moment(content.date).day() !== moment(contents[i - 1].date).day() && (
                      <>
                        <div className="separator"></div>
                        <h5>{moment(content.date).format("dddd Do MMMM")}</h5>
                      </>
                    )}

                    <Link key={i} to={"/contents/" + content._id} className="hover">
                      <div className="row-calendar">
                        <div className="read">
                          <div className="status">
                            <div className={"badge status-" + (content.status || "Draft")}>{content.status || "Draft"}</div>
                          </div>
                          <div className="type">
                            {content._contentType && (
                              <div className={"badge type-" + content._contentType?.name}>{content._contentType?.name}</div>
                            )}
                          </div>
                          <div className="date">{moment(content.date).format("HH:mm")}</div>
                          <div className="location" title={content.location}>
                            {content.location}
                          </div>
                          <div className="city" title={content.city}>
                            {content.city}
                          </div>
                          <div className="description">{content.description}</div>
                        </div>
                      </div>
                    </Link>
                  </Fragment>
                ))}
              </div>
            ) : (
              <div className="table-container">
                <Table filter={filter} config={config} data={contents}></Table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
