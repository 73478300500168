import { useState } from "react";
import api from "../services/api";
import HeaderAdmin from "../components/HeaderAdmin";

export default function Comments() {
  const [hashtag, setHashtag] = useState("");
  const [searching, setSearching] = useState(false);
  const [posts, setPosts] = useState([]);

  const search = async () => {
    setSearching(true);
    let posts = await api.searchByHashtag(hashtag);
    setSearching(false);
    setPosts(posts.data);
  };

  const generateAnswer = async (post, i) => {
    posts[i].generating = true;
    setPosts([...posts]);

    let answer = await api.generateAnswer(post);
    posts[i].answer = answer.data.answer;
    posts[i].generating = false;
    setPosts([...posts]);
  };

  const commentNow = async (post) => {
    window.navigator.clipboard.writeText(post.answer);
    window.open(post.permalink);
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container comments-container">
        <h2 className="mb-60">Comments</h2>

        <label>Search Top posts by hashtag</label>
        <input
          className="mt-10"
          value={hashtag}
          onKeyDown={(e) => (e.key === "Enter" ? search() : null)}
          onChange={(e) => setHashtag(e.target.value)}
        />

        <button className="mt-30" onClick={search}>
          Search Posts
        </button>

        {searching && <div className="mt-30">Searching ...</div>}

        {posts.map((post, i) => (
          <div key={post.id} className="post-container">
            <a href={post.permalink} target="blank">
              <img alt="img" src={post.media_url}></img>
            </a>
            <div>
              <div>{post.caption}</div>
              <button className="small mt-10" onClick={() => generateAnswer(post, i)}>
                Generate answer
              </button>
              {post.generating ? (
                <div className="mt-10">Generating...</div>
              ) : (
                <div>
                  {post.answer && (
                    <div className="mt-10">
                      <div className="answer-content">{post.answer}</div>
                      <button className="small mt-10 dark" onClick={() => commentNow(post)}>
                        Comment Now
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
