import React, { useState, useContext, createContext, useEffect } from "react";
import io from "socket.io-client";

// Context
import { useAuth } from "./useAuth";

export const socketContext = createContext();

function useSocketProvider() {
  const [socket, setSocket] = useState();
  const { user } = useAuth();

  useEffect(() => {
    if (user && !socket) {
      init(user);
    }
    // eslint-disable-next-line
  }, [user]);

  const init = () => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}/${user._id}`, {
      transports: ["polling"],
      // withCredentials: true,
    });

    setSocket(socket);

    socket.on("connect", () => {
      console.log("socket connected");
    });
  };

  return { socket };
}

export function ProvideSocket({ children }) {
  const values = useSocketProvider();
  return <socketContext.Provider value={values}>{children}</socketContext.Provider>;
}

export const useSocket = () => {
  return useContext(socketContext);
};
