import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

// Api
import api from "../services/api";
import Draggable from "react-draggable";

// Components
import HeaderAdmin from "../components/HeaderAdmin";

// Image
import backArrow from "../images/back_arrow.svg";
import igIcons from "../images/igIcons.png";
import igNoImg from "../images/igNoImg2.jpg";
import igUser from "../images/igUser.jpg";
import Moment from "react-moment";

// Context
import { useSocket } from "../context/useSocket";
import { useAuth } from "../context/useAuth";
import moment from "moment";
import Modal from "../components/Modal";

export default function ContentEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [content, setContent] = useState({});
  const [prev, setPrev] = useState();
  const [next, setNext] = useState();
  const [contentTypes, setContentTypes] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [generatingContent, setGeneratingContent] = useState(false);
  const [showModalSocial, setShowModalSocial] = useState(false);
  const [showModalScheduule, setShowModalScheduule] = useState(false);
  const [showInfoPrediction, setShowInfoPrediction] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState(0);
  const [isPublishing, setIsPublishing] = useState(false);
  const [contentType, setContentType] = useState({});
  const { socket } = useSocket();
  const { user } = useAuth();
  const fileInputRef = useRef();
  const videoExt = ["mp4", "mov", "avi"];

  useEffect(() => {
    if (user) fetchContents();
    // eslint-disable-next-line
  }, [id, user]);

  useEffect(() => {
    if (socket) {
      socket.on("image-generated", imageGenerated);
    }

    return () => {
      socket && socket.off("image-generated", imageGenerated);
    };
    // eslint-disable-next-line
  }, [socket, content?._id]);

  useEffect(() => {
    let contentType = contentTypes.find((c) => content._contentType === c._id);
    if (contentType) {
      setContentType(contentType);

      setContent({
        ...{
          enableCaption: contentType.enableCaption,
          captionFont: contentType.captionFont,
          captionSize: contentType.captionSize,
          captionColor: contentType.captionColor,
          captionBg: contentType.captionBg,
          captionLeft: contentType.captionLeft,
          captionTop: contentType.captionTop,
          captionPadding: contentType.captionPadding,
          captionOpacity: contentType.captionOpacity,
          captionMaxWidth: contentType.captionMaxWidth,
          captionMaxHeight: contentType.captionMaxHeight,
          captionRadius: contentType.captionRadius,
          captionLineHeight: contentType.captionLineHeight,
          captionWeight: contentType.captionWeight,
        },
        ...content,
      });
    }
    // eslint-disable-next-line
  }, [content._contentType, contentTypes]);

  const imageGenerated = async (e) => {
    if (e.prediction._content === content._id || e.prediction._content === id) {
      await getPredictions();
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
      setContent(e.content);
    }
  };

  const fetchContents = async () => {
    if (id !== "create") {
      getPredictions();

      api.getContent(id).then((contents) => {
        if (contents.status === 200) {
          setContent(contents.data);
          setNext(contents.data.next);
          setPrev(contents.data.prev);
        }
      });
    } else {
      setContent({});
    }

    api.getContentTypes().then((contentTypes) => {
      setContentTypes(contentTypes.data);
    });
  };

  const getPredictions = async () => {
    let predictions = await api.getPredictions(id);
    if (predictions.status === 200) {
      setPredictions(predictions.data);
    }
  };

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setContent({ ...content, ...newObj });

    if (key === "_contentType") {
      setSelectedPreview(0);
    }
    debouncedSave();
  };

  const save = async () => {
    let contentObj = {
      ...content,
    };
    let data = await api.saveContent(contentObj);
    if (data.status === 200) {
      // setContent(data.data);
      // navigate("/");
    }
  };

  const debouncedSave = useDebouncedCallback(save, 500);

  const remove = async () => {
    if (await window.confirm("Are you sure?")) {
      let data = await api.removeContent(content._id);
      if (data.status === 200) {
        navigate("/");
      }
    }
  };

  const deletePrediction = async (predictionId, index) => {
    if (await window.confirm("Are you sure?")) {
      let data = await api.removePrediction(predictionId, index);
      if (data.status === 200) {
        setPredictions(data.data.predictions);
        setContent(data.data.content);
        setSelectedPreview(0);
      }
    }
  };

  const generateContent = async () => {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    let data = await api.generateContent(content._id);

    if (data.status === 200) {
      setPredictions(data.data);
    } else {
      alert(data.data.message);
    }
  };

  const publish = async () => {
    setIsPublishing(true);
    let data = await api.publishContent(content._id);
    setIsPublishing(false);

    if (data.status === 200) {
      setContent(data.data);
      alert("Content published");
      setShowModalScheduule(false);
    } else {
      alert(data.data.message);
    }
  };

  const generateMetadata = async () => {
    setGeneratingContent(true);
    let data = await api.generateMetadata(content._id);
    setGeneratingContent(false);
    if (data.status === 200) {
      setContent(data.data);
    } else {
      alert(data.data.message);
    }
  };

  const goPrev = async () => {
    navigate("/contents/" + prev);
  };

  const goNext = async () => {
    navigate("/contents/" + next);
  };

  const selectContent = async (url) => {
    if (!content.contents) {
      content.contents = [];
    }
    let i = content.contents.indexOf(url);
    if (i === -1) {
      let contentType = contentTypes.find((c) => content._contentType === c._id);
      if (contentType.instagramType === "Post") {
        content.contents.push(url);
      } else {
        content.contents = [url];
      }
    } else {
      content.contents.splice(i, 1);
    }

    setSelectedPreview(0);
    setValue("contents", content.contents);
  };

  const upload = async (file) => {
    const result = await api.contentUploadMedia(file.target.files[0], content._id);
    file.target.value = "";
    if (result.status === 200) {
      setPredictions(result.data);
    } else {
      alert("Error uploading media");
    }
  };

  const dragImg = (a, b) => {
    let textEl = findTopLeft(a.srcElement.offsetParent);
    let imgEl = findTopLeft(a.srcElement.offsetParent.offsetParent);
    content.captionLeft = textEl.left - imgEl.left;
    content.captionTop = textEl.top - imgEl.top;

    if (content.captionLeft < 0) content.captionLeft = 0;
    if (content.captionTop < 0) content.captionTop = 0;

    setContent({ ...content });
  };

  function findTopLeft(element) {
    var rec = element.getBoundingClientRect();
    return { top: rec.top + window.scrollY, left: rec.left + window.scrollX };
  }

  const removePreferred = async (p, url, i) => {
    p.outputPreferred.splice(i);
    setPredictions([...predictions]);
    api.removePreferred(p._id, url);
  };

  const addPreferred = async (p, url) => {
    p.outputPreferred.push(url);
    setPredictions([...predictions]);
    api.addPreferred(p._id, url);
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container content-edit">
        <div className="title-bar btn-bar">
          <h2>
            <Link to="/" className="back-link">
              <img style={{ height: 30 }} src={backArrow} alt="back" />
            </Link>
            Content Edit
          </h2>

          <div>
            <button className="link" disabled={!prev}>
              <img style={{ height: 30, cursor: "pointer" }} onClick={goPrev} src={backArrow} alt="back" />
            </button>
            <button className="link" disabled={!next}>
              <img style={{ height: 30, cursor: "pointer" }} onClick={goNext} className="flip" src={backArrow} alt="next" />
            </button>
          </div>
        </div>

        <div className="wrapper-layout">
          <div className="wrapper-left">
            <div className="box-border">
              <div className="box-title">Info</div>
              <div className="box-content">
                <div className="label-row">
                  <label>Title</label>
                  <input value={content.title} onChange={(e) => setValue("title", e.target.value)}></input>
                </div>
                <div className="label-row">
                  <label>Description</label>
                  <textarea value={content.description} onChange={(e) => setValue("description", e.target.value)}></textarea>
                </div>
                <div className="label-row">
                  <label>Location</label>
                  <input value={content.location} onChange={(e) => setValue("location", e.target.value)}></input>
                </div>
                <div className="label-row">
                  <label>City</label>
                  <input value={content.city} onChange={(e) => setValue("city", e.target.value)}></input>
                </div>
              </div>

              <div className="btn-bar-right">
                {content._id && (
                  <button onClick={remove} className="red">
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div className="box-border">
              <div className="box-title">Media</div>
              <div className="box-content">
                <div className="label-row">
                  <label>Prompt</label>
                  <textarea value={content.prompt} onChange={(e) => setValue("prompt", e.target.value)}></textarea>
                </div>
                <div className="btn-bar-right">
                  <input style={{ display: "none" }} ref={fileInputRef} onChange={upload} id="uploadButton" type="file"></input>
                  <button disabled={!content._id} onClick={() => fileInputRef.current.click()}>
                    Upload Media
                  </button>

                  <button className="dark" disabled={!content._id} onClick={generateContent}>
                    Generate Image
                  </button>
                </div>

                <div className="prediction-container">
                  {predictions.map((p, i) => (
                    <>
                      {(!p.output || p.output.length === 0 ? [""] : p.output).map((url, i2) => {
                        const ext = url.split(".").pop();

                        return (
                          <div key={"pred_" + url} className="prediction-wrapper">
                            <div className="img-wrapper">
                              <div
                                className={
                                  "media-container " +
                                  (!content.contents || content.contents.indexOf(url) === -1 ? "unselected" : "selected")
                                }
                              >
                                <div className="prediction-title">
                                  <Moment format="MM-DD-YYYY HH:mm">{p.started_at}</Moment>
                                  {p.filename ? (
                                    " - Uploaded"
                                  ) : p.completed_at ? (
                                    <>
                                      {" - Completed in "}
                                      <Moment diff={p.started_at} unit="seconds">
                                        {p.completed_at}
                                      </Moment>
                                      {" seconds"}
                                    </>
                                  ) : (
                                    " Generating..."
                                  )}
                                </div>
                                {p.completed_at && (
                                  <a href={url} target="_blank" rel="noreferrer" className="img-link" key={url}>
                                    {videoExt.indexOf(ext) === -1 ? (
                                      <img className="img-content" alt={content.title + "_" + i + "_" + i2} src={url}></img>
                                    ) : (
                                      <video
                                        controls
                                        className="video-content"
                                        alt={content.title + "_" + i + "_" + i2}
                                        src={url}
                                      ></video>
                                    )}
                                  </a>
                                )}

                                <div className="btn-bar-right">
                                  {p.outputPreferred && p.outputPreferred.indexOf(url) !== -1 ? (
                                    <button onClick={() => removePreferred(p, url, i)} className="btn-star small dark">
                                      &#x2605;
                                    </button>
                                  ) : (
                                    <button onClick={() => addPreferred(p, url)} className="btn-star small">
                                      &#x2606;
                                    </button>
                                  )}
                                  <button className="link small" onClick={() => setShowInfoPrediction(p)}>
                                    Info
                                  </button>
                                  <button className="red small" onClick={() => deletePrediction(p._id, i2)}>
                                    Delete
                                  </button>
                                  {p.completed_at && (
                                    <button
                                      className={
                                        "small" + (!content.contents || content.contents.indexOf(url) === -1 ? "" : " dark")
                                      }
                                      onClick={() => selectContent(url)}
                                    >
                                      {!content.contents || content.contents.indexOf(url) === -1 ? "Select" : "Deselect"}
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ))}
                </div>
                {predictions.length > 12 && (
                  <div className="btn-bar-right">
                    <button className="dark" disabled={!content._id} onClick={generateContent}>
                      Generate Image
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="wrapper-right">
            <div className="box-border">
              <div className="box-title">Social</div>
              <div className="box-content">
                <div className="btn-bar">
                  <div>
                    <div className={"badge status-" + (content.status || "Draft")}>{content.status || "Draft"}</div>
                    {!content.date && <div className="plan-label">Not planned</div>}
                    {content.date && (!content.status || content.status === "Draft") && (
                      <div className="plan-label">Planned for {moment(content.date).format("D MMM HH:mm")}</div>
                    )}
                    {content.status === "Scheduled" && (
                      <div className="plan-label">Scheduled for {moment(content.date).format("D MMM HH:mm")}</div>
                    )}
                    {content.status === "Published" && (
                      <div className="plan-label">Published on {moment(content.publishedDate).format("D MMM HH:mm")}</div>
                    )}
                  </div>
                  <div>
                    <button className="orange" onClick={() => setShowModalScheduule(true)}>
                      Schedule
                    </button>
                  </div>
                </div>

                <div className="label-row social-type">
                  <label>Content Type</label>
                  <select value={content._contentType} onChange={(e) => setValue("_contentType", e.target.value)}>
                    <option></option>
                    {contentTypes.map((c) => (
                      <option key={c._id} value={c._id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Preview */}
                <div className={"preview preview-" + contentType?.instagramType}>
                  <div className="preview-header">
                    <img
                      alt="icon-profile"
                      className="preview-icon-profile"
                      src={user.instagramAccounts?.find((i) => i.id === user.instagramAccount)?.profile_picture_url || igUser}
                    ></img>
                    <div className="preview-name">
                      {user.instagramAccounts?.find((i) => i.id === user.instagramAccount)?.name || "IG_ACCOUNT"}
                    </div>
                  </div>
                  <div className="preview-media">
                    {contentType.instagramType === "Story" && content.enableCaption && (
                      <div
                        style={{
                          position: "absolute",
                          left: content.captionLeft + "px",
                          top: content.captionTop + "px",
                          maxWidth: content.captionMaxWidth + "px",
                          maxHeight: content.captionMaxHeight + "px",
                          zoom: 300 / contentType.aspect_ratios_selection.split("*")[0],
                        }}
                      >
                        <pre
                          class="caption"
                          style={{
                            fontSize: content.captionSize + "px",
                            fontFamily: content.captionFont,
                            padding: content.captionPadding,
                            backgroundColor: content.captionBg,
                            color: content.captionColor,
                            borderRadius: content.captionRadius + "px",
                            opacity: content.captionOpacity,
                            textAlign: "left",
                            whiteSpace: "break-spaces",
                            marginTop: "0px",
                            display: "inline",
                            fontWeight: content.captionWeight,
                            boxDecorationBreak: "clone",
                            webkitBoxDecorationBreak: "clone",
                            lineHeight: content.captionLineHeight,
                          }}
                        >
                          {content.metaDescription}
                        </pre>
                      </div>
                    )}
                    {content.contents &&
                    content.contents[selectedPreview] &&
                    videoExt.indexOf(content.contents[selectedPreview].split(".").pop()) !== -1 ? (
                      <video controls className="preview-img video" src={content.contents[selectedPreview]}></video>
                    ) : (
                      <img
                        className="preview-img"
                        alt="ig-img"
                        src={(content.contents && content.contents[selectedPreview]) || igNoImg}
                      ></img>
                    )}
                  </div>
                  <div className="preview-footer">
                    {contentType?.instagramType === "Post" && content.contents.length > 1 && (
                      <div className="preview-selector">
                        {content.contents.map((url, i) => (
                          <div
                            key={"preview_" + url}
                            className={"preview-selector-dot" + (selectedPreview === i ? " selected" : "")}
                            onClick={() => setSelectedPreview(i)}
                          ></div>
                        ))}
                      </div>
                    )}

                    <div className="preview-btns">
                      <img alt="icon-btn" className="preview-btns-img" src={igIcons}></img>
                    </div>

                    <img
                      alt="icon-profile"
                      className="preview-icon-profile-reel"
                      src={user.instagramAccounts?.find((i) => i.id === user.instagramAccount)?.profile_picture_url || igUser}
                    ></img>
                    <div className="preview-footer-name">
                      {user.instagramAccounts?.find((i) => i.id === user.instagramAccount)?.name || "IG_ACCOUNT"}
                    </div>
                    <div className="preview-footer-desc">
                      {content.metaDescription}
                      {content.hashtags}
                    </div>
                  </div>
                </div>

                <div className="btn-bar-right">
                  <button disabled={!content._id || generatingContent} onClick={generateMetadata}>
                    {generatingContent ? "Generating..." : "Generate Metadata"}
                  </button>
                  <button className="dark" onClick={() => setShowModalSocial(true)}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModalSocial && (
          <Modal close={() => setShowModalSocial(false)} size={contentType.instagramType === "Story" ? "full" : ""}>
            <div className="modal-edit-wrapper columns">
              {contentType.instagramType === "Story" && (
                <div className="modal-edit-wrapper-left">
                  {content.enableCaption && (
                    <Draggable onStop={dragImg} position={{ x: content.captionLeft, y: content.captionTop }}>
                      <div
                        style={{
                          position: "absolute",
                          cursor: "grab",
                          textAlign: "left",
                          maxWidth: content.captionMaxWidth + "px",
                          maxHeight: content.captionMaxHeight + "px",
                        }}
                      >
                        <pre
                          class="caption"
                          style={{
                            fontSize: content.captionSize + "px",
                            fontFamily: content.captionFont,
                            padding: content.captionPadding,
                            backgroundColor: content.captionBg,
                            color: content.captionColor,
                            borderRadius: content.captionRadius + "px",
                            opacity: content.captionOpacity,
                            zIndex: 2,
                            textAlign: "left",
                            whiteSpace: "break-spaces",
                            marginTop: "0px",
                            display: "inline",
                            fontWeight: content.captionWeight,
                            boxDecorationBreak: "clone",
                            webkitBoxDecorationBreak: "clone",
                            lineHeight: content.captionLineHeight,
                          }}
                        >
                          {content.metaDescription}
                        </pre>
                      </div>
                    </Draggable>
                  )}
                  <img alt="preview-img" src={(content.contents && content.contents[0]) || igNoImg}></img>
                </div>
              )}
              <div className="modal-edit-wrapper-right">
                <div className="label-row">
                  <label>Text</label>
                  <textarea
                    value={content.metaDescription}
                    onChange={(e) => setValue("metaDescription", e.target.value)}
                  ></textarea>
                </div>
                {contentType.instagramType !== "Story" && (
                  <div className="label-row">
                    <label>Hashtags</label>
                    <input value={content.hashtags} onChange={(e) => setValue("hashtags", e.target.value)}></input>
                  </div>
                )}
                {contentType.instagramType === "Story" && (
                  <>
                    <div className="label-row">
                      <label>Enable Caption</label>
                      <input
                        type="checkbox"
                        checked={content.enableCaption}
                        onChange={(e) => setValue("enableCaption", e.target.checked)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Font</label>
                      <input value={content.captionFont} onChange={(e) => setValue("captionFont", e.target.value)} />
                    </div>
                    <div className="label-row">
                      <label>Caption Size</label>
                      <input
                        type="number"
                        value={content.captionSize}
                        onChange={(e) => setValue("captionSize", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Radius</label>
                      <input
                        type="number"
                        value={content.captionRadius}
                        onChange={(e) => setValue("captionRadius", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Padding</label>
                      <input value={content.captionPadding} onChange={(e) => setValue("captionPadding", e.target.value)} />
                    </div>
                    <div className="label-row">
                      <label>Caption Opacity</label>
                      <input
                        type="number"
                        step="0.1"
                        min="0"
                        max="1"
                        value={content.captionOpacity}
                        onChange={(e) => setValue("captionOpacity", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Weight</label>
                      <input value={content.captionWeight} onChange={(e) => setValue("captionWeight", e.target.value)} />
                    </div>
                    <div className="label-row">
                      <label>Caption Line Height</label>
                      <input
                        type="number"
                        step="0.1"
                        value={content.captionLineHeight}
                        onChange={(e) => setValue("captionLineHeight", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Color</label>
                      <input
                        className="color-input"
                        value={content.captionColor}
                        onChange={(e) => setValue("captionColor", e.target.value)}
                      />
                      <div className="color-wrapper">
                        <input
                          type="color"
                          value={content.captionColor}
                          onChange={(e) => setValue("captionColor", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="label-row">
                      <label>Caption Bg</label>
                      <input
                        className="color-input"
                        value={content.captionBg}
                        onChange={(e) => setValue("captionBg", e.target.value)}
                      />
                      <div className="color-wrapper">
                        <input type="color" value={content.captionBg} onChange={(e) => setValue("captionBg", e.target.value)} />
                      </div>
                    </div>
                    <div className="label-row">
                      <label>Caption Left</label>
                      <input
                        type="number"
                        min="0"
                        value={content.captionLeft}
                        onChange={(e) => setValue("captionLeft", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Top</label>
                      <input
                        min="0"
                        type="number"
                        value={content.captionTop}
                        onChange={(e) => setValue("captionTop", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Max Width</label>
                      <input
                        type="number"
                        value={content.captionMaxWidth}
                        onChange={(e) => setValue("captionMaxWidth", e.target.value)}
                      />
                    </div>
                    <div className="label-row">
                      <label>Caption Max Height</label>
                      <input
                        type="number"
                        value={content.captionMaxHeight}
                        onChange={(e) => setValue("captionMaxHeight", e.target.value)}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        )}

        {showModalScheduule && (
          <Modal close={() => setShowModalScheduule(false)} size="medium">
            <div className="modal-edit-wrapper">
              <div className="label-row mt-40">
                <label>Date</label>
                <input
                  type="datetime-local"
                  value={moment(content.date).format("YYYY-MM-DD HH:mm:ss")}
                  onChange={(e) => setValue("date", e.target.value)}
                ></input>
              </div>
              <div className="label-row">
                <input type="checkbox" checked={content.notify} onChange={(e) => setValue("notify", e.target.checked)}></input>
                <label>Notify me instead of publish</label>
              </div>

              {!content.status ||
                (content.status !== "Draft" && (
                  <div className="align-left">
                    <button
                      className="link small"
                      onClick={() => {
                        setValue("status", "Draft");
                        setShowModalScheduule(false);
                      }}
                    >
                      Set as Draft
                    </button>
                  </div>
                ))}

              {content.status !== "Published" && (
                <div className="align-left">
                  <button
                    className="link small"
                    onClick={() => {
                      setValue("status", "Published");
                      setShowModalScheduule(false);
                    }}
                  >
                    Set as Published
                  </button>
                </div>
              )}

              <div className="btn-bar-right mt-30">
                <button className="green" onClick={publish} disabled={isPublishing}>
                  {isPublishing ? "Publishing..." : "Publish Now"}
                </button>
                <button
                  className="orange"
                  onClick={() => {
                    setValue("status", "Scheduled");
                    setShowModalScheduule(false);
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
          </Modal>
        )}

        {showInfoPrediction && (
          <Modal close={() => setShowInfoPrediction(false)}>
            <div className="modal-left">
              {Object.keys(showInfoPrediction.input).map((field) => (
                <div className="param-row">
                  <label>{field}</label>
                  <div>{showInfoPrediction.input[field]}</div>
                </div>
              ))}
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
