import { useState, useEffect } from "react";
import api from "../services/api";
import HeaderAdmin from "../components/HeaderAdmin";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CalendarAI() {
  const [uploads, setUploads] = useState([]);
  const [showPreferred, setShowPreferred] = useState(false);

  useEffect(() => {
    fetchContents();
  }, []);

  const fetchContents = async () => {
    let uploads = await api.getMedia();
    setUploads(uploads.data);
  };

  const removePreferred = async (u) => {
    u.preferred = false;
    setUploads([...uploads]);
    api.removePreferred(u._prediction, u.output);
  };

  const addPreferred = async (u) => {
    u.preferred = true;
    setUploads([...uploads]);
    api.addPreferred(u._prediction, u.output);
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2 className="mb-60">All your media</h2>

        <div className="btn-bar">
          <button onClick={() => setShowPreferred(!!!showPreferred)}>{showPreferred ? "Show All" : "Show only preferred"}</button>
        </div>
        <div className="modal-img-select">
          {uploads.map(
            (u) =>
              (!showPreferred || (showPreferred && u.preferred)) && (
                <div key={u.output} className="img-select-container">
                  {u.preferred ? (
                    <button onClick={() => removePreferred(u)} className="btn-star dark">
                      &#x2605;
                    </button>
                  ) : (
                    <button onClick={() => addPreferred(u)} className="btn-star">
                      &#x2606;
                    </button>
                  )}
                  <a target="_blank" rel="noreferrer" href={u.output}>
                    <LazyLoadImage src={u.output} alt="Img not found"></LazyLoadImage>
                  </a>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
}
