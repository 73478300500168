import { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.svg";
import api from "../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/useAuth";

const TITLE = "APP Template - Set Password";

export default function SetPassword() {
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  let { id, token } = useParams();
  let { checkAuth } = useAuth();
  const navigate = useNavigate();

  const reset = async () => {
    let result = await api.resetPassword(id, token, password);

    if (result.status === 200 && result.data.token) {
      localStorage.setItem("user_token", result.data.token);
      await checkAuth();
      navigate("/");
    } else {
      localStorage.removeItem("user_token");
      setError(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-container">
        <img className="login-logo" src={logo} alt="Logo"></img>
        <h1>APP Template</h1>
        <div className="login-form">
          <input onChange={(e) => setPassword(e.target.value)} placeholder="password" type="password"></input>
        </div>
        <button className="big" onClick={() => reset()}>
          Set Password
        </button>

        {error && <div className="error mt-15">Error</div>}
      </div>
    </>
  );
}
