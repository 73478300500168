// Components
import HeaderAdmin from "../components/HeaderAdmin";

// Context
import FacebookLogin from "@greatsumini/react-facebook-login";
import api from "../services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/useAuth";
import Moment from "react-moment";

export default function Profile() {
  const [userProfile, setUserProfile] = useState({});
  const [conversations, setConversations] = useState([]);
  const { user, checkAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      let data = await api.getUser();
      setUserProfile(data.data);

      let conv = await api.getConversations();
      setConversations(conv.data);
    };

    fetchUser();
    // eslint-disable-next-line
  }, [user]);

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setUserProfile({ ...userProfile, ...newObj });
  };

  const save = async () => {
    let data = await api.saveUser(userProfile);

    if (data.status === 200) {
      checkAuth();
      navigate("/");
    }
  };

  const toggleConversation = async (convIndex) => {
    conversations[convIndex].ignore = !!!conversations[convIndex].ignore;
    let data = await api.setConversationIgnore(conversations[convIndex]._id, conversations[convIndex].ignore);
    if (data.status === 200) {
      setConversations([...conversations]);
    }
  };

  const searchPage = async () => {
    let data = await api.searchPage(userProfile.search);

    if (data.status === 200) {
      alert(JSON.stringify(data.data));
    } else {
      alert("ERROR: " + JSON.stringify(data.data));
    }
  };

  const getLastPosts = async () => {
    let data = await api.getPosts();

    if (data.status === 200) {
      alert("The last content of the page is : " + data.data.data[0].story);
    } else {
      alert("ERROR: " + JSON.stringify(data.data));
    }
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Profile</h2>

        <div className="btn-bar">
          <div className="btn-bar-left">
            <button className="btn" onClick={save}>
              Save
            </button>

            <button className="btn" onClick={searchPage}>
              Search Page
            </button>

            <button className="btn" onClick={getLastPosts}>
              Get last posts
            </button>
          </div>

          <FacebookLogin
            appId={process.env.REACT_APP_INSTAGRAM_APP_ID}
            scope="public_profile,instagram_basic,instagram_content_publish,business_management,instagram_manage_messages,pages_messaging,instagram_manage_comments,pages_read_engagement,pages_read_user_content"
            onSuccess={(response) => {
              console.log("Login Success!", response);
              api.instagramLogin(response).then((data) => {
                setUserProfile(data.data);
                alert("Instagram connected");
              });
            }}
            onFail={(error) => {
              console.log("Login Failed!", error);
            }}
            onProfileSuccess={(response) => {
              console.log("Get Profile Success!", response);
            }}
          />
        </div>

        <div>
          <div className="label-row">
            <label>Search public page</label>
            <input value={userProfile?.search} onChange={(e) => setValue("search", e.target.value)} />
          </div>

          <div className="label-row">
            <label>Instagram Account</label>
            <select value={userProfile?.instagramAccount} onChange={(e) => setValue("instagramAccount", e.target.value)}>
              <option></option>
              {userProfile?.instagramAccounts?.map((i) => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
          </div>
          <div className="label-row">
            <label>Notification Email</label>
            <input value={userProfile?.notificationEmail} onChange={(e) => setValue("notificationEmail", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Prompt calendar</label>
            <input value={userProfile?.promptCalendar} onChange={(e) => setValue("promptCalendar", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Prompt image</label>
            <input value={userProfile?.promptImage} onChange={(e) => setValue("promptImage", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Prompt Chat</label>
            <input value={userProfile?.promptChat} onChange={(e) => setValue("promptChat", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Prompt Comment</label>
            <input value={userProfile?.promptComment} onChange={(e) => setValue("promptComment", e.target.value)} />
          </div>
          <div className="label-row">
            <label>Send Email Published</label>
            <input
              type="checkbox"
              checked={userProfile?.sendEmailSuccess}
              onChange={(e) => setValue("sendEmailSuccess", e.target.checked)}
            />
          </div>
          <div className="label-row">
            <label>Send Email Fail</label>
            <input
              type="checkbox"
              checked={userProfile?.sendEmailFail}
              onChange={(e) => setValue("sendEmailFail", e.target.checked)}
            />
          </div>
        </div>

        <div className="conv-list">
          <h2>Ignore Conversation</h2>
          {conversations.map((conv, convIndex) => (
            <div key={"conv_" + convIndex}>
              <input type="checkbox" checked={conv.ignore} onChange={() => toggleConversation(convIndex)}></input>
              <b style={{ width: 100, display: "inline-block" }}>{conv.type}</b>
              <b>
                <Moment format="MM-DD HH:mm">{conv.lastMessage}</Moment>
              </b>
              {"  -  " + (conv.lastMessageContent || conv.sender)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
